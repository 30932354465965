import React from "react";
import { useTranslation } from "react-i18next";
import TermsPage from "../User/TermsPage";

const ContactPage = (): JSX.Element => {

  const { t } = useTranslation("translation");
  return (
   
      <TermsPage />
  );
};
export default ContactPage;
